.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
}

/* Item of Size Dropdown */

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36pt";
  font-size: 16px !important;
}
/* END Item of Size Dropdown */

/* Label of Size Dropdown */

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before {
  content: "8pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {
  content: "10pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: "24pt";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before {
  content: "36pt";
  font-size: 16px !important;
}
/* END Label of Size Dropdown */
.ql-container {
  height: 300px !important;
}
