.formCard {
  min-width: 400px !important;
  width: auto;
  padding: 3rem;
}

@media screen and (max-width: 1024px) {
  .formCard {
    padding: 1rem;
  }
}
