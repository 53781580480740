.wrapper {
  height: 90vh;
}

.passwordInput {
  position: relative;
}

.passwordInput svg {
  bottom: 13px;
  position: absolute;
  right: 10px;
  width: 16px;
}

.passwordField {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.form_Card {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}

@media only screen and (max-width: 636px) {
  .form_Card {
    width: 350px !important;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 10px;
    justify-content: center;
  }
  /* your CSS here */
}
:global(.was-validated) ::before,
::after {
  box-sizing: border-box;
}

:local(.login) :global(.form-control.is-invalid),
:global(.form-control.is-valid),
:global(.was-validated) :global(.form-control:invalid) {
  padding-right: calc(2.4em + 0.1875rem) !important;
  background-position: right calc(1.375em + 0.1875rem) center !important;
}
:local(.login) :global(.form-control.is-invalid),
:global(.form-control.is-valid),
:global(.was-validated) :global(.form-control:valid) {
  padding-right: calc(2.4em + 0.1875rem) !important;
  background-position: right calc(1.375em + 0.1875rem) center !important;
}
:local(.login) :global(.form-control.is-invalid),
:global(.form-control.is-valid),
:global(.was-validated) :global(.password) :global(.form-control:valid) {
  background-image: none !important;
}
:local(.login) :global(.form-control) {
  padding-right: 30px !important;
}
