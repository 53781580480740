.tooltipp {
  position: relative;
  display: flex;
}
.tooltipp .tooltiptext {
visibility: hidden;
width: 5rem;
background-color: #9537FF;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 2px;
font-size: 12px;
/* padding: 20px; */
/* Position the tooltip */
position: absolute;
z-index: 1;
top: 26%;
right: 104%;
}
.tooltipp:hover .tooltiptext {
visibility: visible;
}
