body {
  font-family: "Cabin", sans-serif;
  background: #f5f6fa !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  font-family: "Cabin", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.link {
  color: #8834ff;
  text-decoration: none;
}
.dropdown-toggle::after {
  display: none;
}
.link:hover {
  color: #8834ff;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #d957f9 !important;
}

input[type="checkbox"]:checked {
  background-color: #8834ff;
  border: 1px solid #8834ff;
}

.button-primary {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
}
a > button {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
}

input:invalid[focused="true"] {
  border: 1px solid red !important;
}

input:invalid[focused="true"] ~ div {
  display: block;
  color: red;
}

.shadowMain {
  box-shadow: 0px 2px 4px rgba(39, 53, 70, 0.1);
}

.react-select input:focus {
  border: 0 !important;
}

.backFormButton {
  border-radius: 4px;
  background-color: blue;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.backFormButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.backFormButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transform: rotate(180deg);
  transition: 0.5s;
}

.backFormButton:hover span {
  padding-left: 25px;
}

.backFormButton:hover span:after {
  opacity: 1;
  left: 0;
}

.asterik-label::before {
  content: "* ";
  color: red;
}

.tox-statusbar__branding svg {
  display: none;
}

/* .react-select div{
    z-index: 2;
} */
/* .react-select:active{
    background-color: blue;
}  */

.react-select > div:first-of-type + div {
  z-index: 3;
}

.active,
.dot:hover {
  background-color: #ffffff00 !important;
}
.accordion-button:not(.collapsed) {
  color: white;
}
.accordion-button {
  color: white;
}
.accordion--bs-accordion-active-color {
  color: white;
}
/* .accordion-button::after {
   content: "✅" !important; 
   content: none !important; 
} */
.accordion-button:not(.collapsed)::after {
  /* background-image: none !important;
    margin-left: 0 !important;
    width:0px */
  background-image: var(--bs-accordion-btn-icon);
}
.accordion-button:not(.collapsed) {
  display: flex;
  justify-content: space-between;
}
.accordion-button {
  background-color: #f8f6ff !important;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: white;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
.dot-flashing-black {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing-black::before,
.dot-flashing-black::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing-black::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing-black::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing-black {
  0% {
    background-color: rgb(0, 0, 0);
  }
  50%,
  100% {
    background-color: rgba(102, 98, 116, 0.2);
  }
}

.showing-entries {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  width: 150px;
  height: 36px;
  padding: 0 20px;
  color: hsl(0, 0%, 20%);
}
/* .fade:not(.show) {
  display: inline-block !important;
} */

/* .carousel-item {
  display: inline-block !important;
} */

.overlay {
  opacity: 0.7;
}


.react-select div:has( > .dropdown-no-option-action){
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.react-select div:has( > .dropdown-no-option-action):hover {
  background-color: #F5F6FA;
}



div:has(> .react-select-container__control):not(.form-control) > .react-select-container__control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.13rem !important;
}

.form-control:has(> .react-select-container__control) {
  padding: 0 !important;
}

.form-control:has(> .react-select-container__control) > .react-select-container__control{
  border: 0 !important;
}
.form-control:has(> .react-select-container__control) .react-select-container__input {
  border: 0 !important;
}
.form-control:has(> .react-select-container__control):has(.react-select-container__control--is-disabled) {
  background: #e9ecef !important;
}


.react-select-container__menu{
  z-index: 3 !important;
}
